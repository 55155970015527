import { Injectable } from '@angular/core';

import { isNullOrUndefined } from '@abp/ng.core';

import { GridColumnFilterType, GridColumnTypes } from '@nexweb/shared/ui/column';
import { GridConfig } from '@nexweb/shared/ui/kgw/models';

import { KgwConfigService } from './kgw-config.service';

@Injectable({
  providedIn: 'root',
})
export class KgwConfigLoaderService {
  constructor(private configService: KgwConfigService) {}

  public loadGridConfig(config: GridConfig): GridConfig {
    //load pageSize
    if (isNullOrUndefined(config?.state)) {
      config.state = { take: this.configService.config.pageSize };
    }

    if (isNullOrUndefined(config?.state?.take)) {
      config.state.take = this.configService.config.pageSize;
    }

    if (isNullOrUndefined(config.filterable)) {
      config.filterable = this.configService.config.filterable as GridColumnFilterType;
    }

    if (config.readonly === true) {
      if (config.columns.some((x) => x.type === GridColumnTypes.detail)) {
        if (isNullOrUndefined(config.columns[0].width)) {
          config.columns[0].width = this.configService.config.defaultColumnWidth;
        }
      }
    }

    if (isNullOrUndefined(config.settings?.columnsPerRow)) {
      config.settings.columnsPerRow = this.configService.config.columnsPerRow;
    }

    return config;
  }
}
